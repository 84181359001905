
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { registerToken } from '../backendApi/registerFirebase'

const firebaseConfig = {
    apiKey: "AIzaSyBdRTOehd38B8OgAPYs4Y69A0Tm-H2GBvo",
    authDomain: "kissantech-8eb4a.firebaseapp.com",
    projectId: "kissantech-8eb4a",
    storageBucket: "kissantech-8eb4a.appspot.com",
    messagingSenderId: "573745713602",
    appId: "1:573745713602:web:53126685b46b5c403f065b",
    measurementId: "G-26X3PKPKLG"
}

initializeApp(firebaseConfig)
const messaging = getMessaging()

let chatWindow

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload)

    const url = `https://administration.kissan-tech.com/additionalDetails/${payload.data.case_id}?chat=${payload.data.user}`
    const notificationTitle = payload.data.title
    const notificationOptions = {
        body: payload.data.body
    }
    if (window.location.href !== url) {
        const notification = new Notification(notificationTitle, notificationOptions)

        notification.onclick = function (event) {
            event.preventDefault()
            if (!chatWindow || chatWindow.closed)
                chatWindow = window.open(url, 'chat_opened')
            else
                chatWindow.focus()
            notification.close()
        }
    }
})

const requestPermission = () => {
    getToken(messaging, { vapidKey: 'BCEeCCp1Piv1G_c8mfc_w_JZU5d9glzwzeVSJqZVu_32zuE3ZxyhxLZa0nmw_gSlpYH57S90jHszMdHCkY022VU' })
        .then(async (currentToken) => {
            if (currentToken) {
                console.log(currentToken)
                await registerToken(currentToken)
                    .then(resp => console.log(resp))
                    .catch(e => console.log(e))
                // Send the token to your server and update the UI if necessary
                // ...
            }
            else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.')
                // ...
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
            // ...
        })
}

export default requestPermission