import api from './index'

export function adminLogin(adminCredentials) {
  console.log(adminCredentials)
  return api.get(`/login/${adminCredentials.email}/${adminCredentials.password}`)
}

export function adminRegister(newAdmin) {
  return api.post(`/register`, newAdmin)
}
