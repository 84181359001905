import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import CheckRequest from './components/CheckRequest';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const EcomPage = React.lazy(() => import('./pages/Ecommerce'));
const FarmersPage = React.lazy(() => import('./pages/FarmersPage'));
const ConsultantsPage = React.lazy(() => import('./pages/ConsultantsPage'));
const FarmsPage = React.lazy(() => import('./pages/MaslaqPage'));
const CasesPage = React.lazy(() => import('./pages/CasesPage'));
// const QueryPage = React.lazy(() => import('./pages/QueryPage'));
const farmersDetails = React.lazy(() => import('./pages/farmersDetails'));
const caseDetails = React.lazy(() => import('./pages/caseDetails'));
const additionalDetails = React.lazy(() => import('./pages/additionalDetails'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("authToken") ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
}

const App = (props) => {

  return (
    <BrowserRouter basename={getBasename()}>
      <GAListener>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />
          <MainLayout breakpoint={props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <PrivateRoute exact path="/" component={DashboardPage} />

              <PrivateRoute exact path="/e-commerce" component={EcomPage} />
              <PrivateRoute exact path="/farmers" component={FarmersPage} />
              <PrivateRoute exact path="/consultants" component={ConsultantsPage} />
              <PrivateRoute exact path="/farms" component={FarmsPage} />
              <PrivateRoute exact path="/cases" component={CasesPage} />
              <PrivateRoute exact path="/cases/:consultant_id" component={CasesPage} />
              <PrivateRoute exact path="/farmersDetails/:id" component={farmersDetails} />
              <PrivateRoute exact path="/farmDetails/:id" component={caseDetails} />
              <PrivateRoute exact path="/additionalDetails/:id" component={additionalDetails} />
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </GAListener>
    </BrowserRouter>
  );
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(CheckRequest(App));
