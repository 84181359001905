import logo200Image from '../assets/img/logo/logo_200.png'
import PropTypes from 'prop-types'
import React from 'react'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import { Spinner, Alert } from 'reactstrap'
import { adminLogin } from '../backendApi/Auth'
import requestPermission from '../services/firebase'

class AuthForm extends React.Component {
  state = {
    email: '',
    password: '',
    errors: [],
    isLoading: false,
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP
  }

  changeAuthState = authState => event => {
    event.preventDefault()

    this.props.onChangeAuthState(authState)
  }

  handleSubmit = event => {
    event.preventDefault()
  }

  loginAdmin = () => {
    this.setState({ isLoading: true })
    let adminObj = {
      email: this.state.email,
      password: this.state.password,
    }
    console.log('login admin====', adminObj)
    adminLogin(adminObj)
      .then(response => {
        this.setState({
          isLoading: false,
        })
        if (response.data.success) {
          console.log(response.data.token, 'show admin login response=========')
          localStorage.setItem('authToken', response.data.token)
          this.props.history.push('/')
          requestPermission()
        }
        else {
          this.setState({
            errors: this.state.errors.concat({ message: "wrong credentials" }),
          })
          setTimeout(() => {
            this.setState({ errors: [] })
          }, 3000)
          console.log(123);
        }
      })
      .catch(err => {
        this.setState({ errors: [] })
        if (err.response && err.response.data) {
          console.log(err.response)
          // let errorObject=JSON.parse(JSON.stringify(err))
          // console.log(errorObject,'==========',err)
          this.setState({
            errors: this.state.errors.concat(err.response.data),
            isLoading: false,
          })
          setTimeout(() => {
            this.setState({ errors: [] })
          }, 3000)
          // console.log(err.response.status)
          // console.log(err.response.headers)
        } else if (err.request) {
          err = {
            message:
              'An error occured while processing request. Might be due to weak network or internal server error',
          }
          this.setState({
            errors: this.state.errors.concat(err),
            isLoading: false,
          })
          setTimeout(() => {
            this.setState({ errors: [] })
          }, 3000)
          console.log(err.request)
        } else {
          err = { message: 'An error occured' }
          this.setState({
            errors: this.state.errors.concat(err),
            isLoading: false,
          })
          setTimeout(() => {
            this.setState({ errors: [] })
          }, 3000)
          console.log('Error', err.message)
        }
        console.log(err.config)
      })
  }

  signupAdmin = () => {
    console.log('signup user')
    this.props.history.push('/login')
  }

  renderButtonText() {
    const { buttonText } = this.props

    if (!buttonText && this.isLogin) {
      return 'Login'
    }

    if (!buttonText && this.isSignup) {
      return 'Signup'
    }

    return buttonText
  }

  displayErrors = errors =>
    errors.map((error, i) => (
      <Alert color="secondary" key={i}>
        {error.message}
      </Alert>
    ))

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const {
      showLogo,
      // usernameLabel,
      // usernameInputProps,
      // passwordLabel,
      // passwordInputProps,
      // confirmPasswordLabel,
      // confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <FormGroup>
          <Label for="userEmail">Email</Label>
          <Input
            type="email"
            name="email"
            placeholder="Enter Email "
            value={this.state.email}
            onChange={this.onChange.bind(this)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="userPassword">Password</Label>
          <Input
            type="password"
            name="password"
            placeholder="Enter Password"
            value={this.state.pasword}
            onChange={this.onChange.bind(this)}
          />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <Input
              type="password"
              name="password"
              placeholder="Enter Confirm Password"
            />
          </FormGroup>
        )}
        <hr />
        {this.state.errors.length > 0 && this.displayErrors(this.state.errors)}

        <Button
          disabled={this.state.isLoading}
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.isLogin ? this.loginAdmin : this.signupAdmin}
        >
          {this.state.isLoading ? (
            <Spinner size="md" color="primary" />
          ) : (
            this.renderButtonText()
          )}
        </Button>

        {/* <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div> */}

        {children}
      </Form>
    )
  }
}

export const STATE_LOGIN = 'LOGIN'
export const STATE_SIGNUP = 'SIGNUP'

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  // usernameLabel: PropTypes.string,
  // usernameInputProps: PropTypes.object,
  // passwordLabel: PropTypes.string,
  // passwordInputProps: PropTypes.object,
  // confirmPasswordLabel: PropTypes.string,
  // confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
}

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  // usernameLabel: 'Email',
  // usernameInputProps: {
  //   type: 'email',
  //   placeholder: 'your@email.com',
  // },
  // passwordLabel: 'Password',
  // passwordInputProps: {
  //   type: 'password',
  //   placeholder: 'your password',
  // },
  // confirmPasswordLabel: 'Confirm Password',
  // confirmPasswordInputProps: {
  //   type: 'password',
  //   placeholder: 'confirm your password',
  // },
  onLogoClick: () => { },
}

export default AuthForm
